<template>
  <!-- BODY -->
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <!-- Form -->
    <b-form
      class=""
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-card>
        <b-card-title>
          Crear producto
        </b-card-title>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <!-- Product Name -->
            <validation-provider
              #default="validationContext"
              name="Nombre del producto"
              rules="required|uniqueProductName"
            >
              <b-form-group
                label="Nombre del producto"
                label-for="product_name"
              >
                <b-form-input
                  id="product_name"
                  v-model="productObject.name"
                  :state="getValidationState(validationContext)"
                  trim
                  debounce="500"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <!-- Product Code -->
            <validation-provider
              #default="validationContext"
              name="Código"
              rules="required|alpha-num|uniqueProductCode"
            >
              <b-form-group
                label="Código"
                label-for="product_code"
              >
                <b-form-input
                  id="product_code"
                  v-model="productObject.code"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <!-- Category -->
            <validation-provider
              #default="validationContext"
              name="Categoria"
              rules="required"
            >
              <b-form-group
                label="Categoria"
                label-for="product_category"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="productObject.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productsCategoriesList"
                  :clearable="false"
                  label="name"
                  :reduce="val => val.id"
                  input-id="product_category"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <!-- Brand -->
            <validation-provider
              #default="validationContext"
              name="Marca"
              rules="required"
            >
              <b-form-group
                label="Marca"
                label-for="product_brand"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="productObject.brand"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productsBrandList"
                  :clearable="false"
                  label="name"
                  :reduce="val => val.id"
                  input-id="product_brand"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <!-- Status -->
            <validation-provider
              #default="validationContext"
              name="Estado"
              rules="required"
            >
              <b-form-group
                label="Estado"
                label-for="product_status"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="productObject.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productStatusesList"
                  :clearable="false"
                  label="name"
                  :reduce="val => val.id"
                  input-id="product_status"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <!-- Price PEN -->
            <validation-provider
              #default="validationContext"
              name="Precio soles"
              rules="required"
            >
              <b-form-group
                label="Precio soles"
                label-for="price_pen"
              >
                <b-form-input
                  id="price_pen"
                  v-model="productObject.pricePEN"
                  type="number"
                  step="0.000001"
                  :state="getValidationState(validationContext)"
                  trim
                  @keyup="pricePENListen"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <!-- Price USD -->
            <b-form-group
              label="Precio dólares"
              label-for="price_usd"
            >
              <b-form-input
                id="price_usd"
                v-model="productObject.priceUSD"
                trim
                step="0.000001"
                @keyup="priceUSDListen"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <!-- % Discount -->
            <b-form-group
              label="% Descuento"
              label-for="percentage_discount"
            >
              <b-form-input
                id="percentage_discount"
                v-model="productObject.discount"
                type="number"
                trim
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <!-- Stock -->
            <validation-provider
              #default="validationContext"
              name="Stock"
              rules="required|numeric"
            >
              <b-form-group
                label="Stock"
                label-for="stock"
              >
                <b-form-input
                  id="stock"
                  v-model="productObject.stock"
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Descripción breve"
              rules="required"
            >
              <b-form-group
                label="Descripción breve"
                label-for="product_information_short"
              >
                <quill-editor
                  id="product_information_short"
                  v-model="productObject.descriptionShort"
                  :state="getValidationState(validationContext)"
                  :options="snowOption"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Información del producto"
              rules="required"
            >
              <b-form-group
                label="Información del producto"
                label-for="product_information"
              >
                <quill-editor
                  id="product_information"
                  v-model="productObject.descriptionProduct"
                  :state="getValidationState(validationContext)"
                  :options="snowOption"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Descripción en la Cotización"
              rules="required"
            >
              <b-form-group
                label="Descripción en la Cotización"
                label-for="description_in_quotation"
              >
                <quill-editor
                  id="description_in_quotation"
                  v-model="productObject.descriptionInQuotation"
                  :state="getValidationState(validationContext)"
                  :options="snowOption"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
      </b-card>

      <b-card>
        <b-card-title>
          Imagenes del producto
        </b-card-title>
        <b-row align-h="between">
          <b-col
            cols="12"
            md="5"
          >
            <validation-provider
              #default="validationContext"
              name="Imagen principal"
              rules="required"
            >
              <b-form-group
                label="Imagen principal"
                label-for="product_image_main"
              >
                <b-form-file
                  id="product_image_main"
                  ref="refInputElImageMain"
                  v-model="productObject.imageMain"
                  placeholder="Subir imagen 1000x1000px..."
                  drop-placeholder="Drop file here..."
                  :state="getValidationState(validationContext)"
                  @input="renderImageMain"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div
              v-if="showImageMain"
              class="my-1"
            >
              <b-img
                thumbnail
                fluid
                :src="showImageMain"
                alt="Image Desktop"
              />
            </div>
          </b-col>

          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="Galería (max 3 fotos 1000x1000px)"
              label-for="product_images_list"
            >
              <b-form-file
                id="product_images_list"
                ref="refInputElImageGallery"
                v-model="productObject.imageGallery"
                multiple
                placeholder="Subir imagen (max 3 fotos 1000x1000px)..."
                drop-placeholder="Drop file here..."
                @input="imageGalleryRender"
              />
            </b-form-group>
            <b-row>
              <b-col
                v-for="(file,index) in showImageGallery"
                :key="index"
                cols="12"
                md="3"
              >
                <div
                  class="my-1"
                >
                  <b-img
                    thumbnail
                    fluid
                    :src="file"
                    alt="Image gallery"
                  />
                </div>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <!-- Card Specifications -->
          <b-card>
            <b-card-title>
              Especificaciones del producto
            </b-card-title>
            <b-row>
              <b-col
                v-for="(element,index) in productObject.specifications"
                :key="index"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Nombre"
                  label-for="product_specification_name"
                >
                  <b-form-input
                    id="product_specification_name"
                    v-model="element.name"
                    trim
                  />
                </b-form-group>
                <b-form-group
                  label="Valor"
                  label-for="product_specification_value"
                >
                  <b-form-input
                    id="product_specification_value"
                    v-model="element.value"
                    trim
                  />
                </b-form-group>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon"
                  @click="removeSpecification(index)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1"
              @click="addSpecifications"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Agregar</span>
            </b-button>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <!-- Card Videos -->
          <b-card>
            <b-card-title>
              PDF's
            </b-card-title>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Ficha técnica"
                  label-for="product_datasheet"
                >
                  <b-form-file
                    id="product_datasheet"
                    v-model="productObject.pdfDatasheet"
                    placeholder="Subir pdf..."
                    drop-placeholder="Drop file here..."
                  />
                </b-form-group>

                <b-form-group
                  label="Lista de imagenes"
                  label-for="product_images_pdf"
                >
                  <b-form-file
                    id="product_images_pdf"
                    v-model="productObject.pdfImages"
                    placeholder="Subir pdf..."
                    drop-placeholder="Drop file here..."
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <!-- Card Videos -->
          <b-card>
            <b-card-title>
              Videos del producto
            </b-card-title>
            <b-row>
              <b-col
                v-for="(video,index) in productObject.videos"
                :key="index"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="ID Del Video"
                  label-for="product_video"
                >
                  <b-form-input
                    id="product_video"
                    v-model="video.url"
                    trim
                  />
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon"
                    @click="removeVideo(index)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1"
              @click="addVideo"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Agregar</span>
            </b-button>
          </b-card>
        </b-col>
      </b-row>

      <!-- Form Actions -->
      <div class="d-flex justify-content-start mb-3">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="submit"
        >
          Guardar
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="$router.push({name: 'products'})"
        >
          Cancelar
        </b-button>
      </div>

    </b-form>
  </validation-observer>
<!--  </b-card>-->
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BCardTitle, BFormInvalidFeedback, BFormFile, BImg,
} from 'bootstrap-vue'
import {
  required, alphaNum, email, numeric, digits,
} from '@validations'
import { quillEditor } from 'vue-quill-editor'
import { onUnmounted, ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import productHandler from '@/views/dramox/product/productHandler'
import store from '@/store'
import productStoreModule from '@/views/dramox/product/productStoreModule'
import { useInputImageMultipleRenderer, useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

extend('uniqueProductName', {
  validate(value) {
    return new Promise(resolve => {
      store.dispatch('app-products/verifyProductExists', { name: value })
        .then(response => {
          resolve({
            valid: !response.data.payload.results.exists,
          })
        })
        .catch(() => {
          resolve({
            valid: false,
          })
        })
    })
  },
  message: 'EL NOMBRE DEL PRODUCTO YA EXISTE',
})

extend('uniqueProductCode', {
  validate(value) {
    return new Promise(resolve => {
      store.dispatch('app-products/verifyProductExists', { code: value })
        .then(response => {
          resolve({
            valid: !response.data.payload.results.exists,
          })
        })
        .catch(() => {
          resolve({
            valid: false,
          })
        })
    })
  },
  message: 'EL CÓDIGO DEL PRODUCTO YA EXISTE',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    quillEditor,
    BCardTitle,
    BFormInvalidFeedback,
    vSelect,
    BFormFile,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      numeric,
      digits,
      snowOption: {
        theme: 'snow',
      },
    }
  },
  setup({ emit }) {
    const BUSINESS_APP_STORE_MODULE_NAME = 'app-products'

    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const blankProductData = {
      name: '',
      code: '',
      category: '',
      brand: '',
      pricePEN: '',
      priceUSD: '',
      discount: '',
      stock: '',
      status: '',
      descriptionInQuotation: '',
      descriptionProduct: '',
      descriptionShort: '',
      pdfDatasheet: null,
      pdfImages: null,
      imageMain: null,
      imageGallery: [],
      videos: [],
      specifications: [],
    }
    const productObject = ref(JSON.parse(JSON.stringify(blankProductData)))
    const exchangeRateValue = ref(0)
    const refInputElImageMain = ref(null)
    const refInputElImageGallery = ref(null)
    const showImageMain = ref(null)
    const showImageGallery = ref([])

    const resetProductData = () => {
      productObject.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const addVideo = () => {
      productObject.value.videos.push({ url: '' })
    }
    const removeVideo = index => {
      productObject.value.videos.splice(index, 1)
    }

    const addSpecifications = () => {
      productObject.value.specifications.push({ name: '', value: '' })
    }
    const removeSpecification = index => {
      productObject.value.specifications.splice(index, 1)
    }

    const productStatusesList = [
      { id: 1, name: 'Público' },
      { id: 2, name: 'Privado' },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProductData)

    store
      .dispatch('app-products/fetchExchangeRate')
      .then(response => {
        const { exchange_rate: exchangeRate } = response.data.payload.results
        exchangeRateValue.value = exchangeRate
      })
      .catch(e => {
        console.log(e, 'error fetch excnage rate')
      })

    const {
      productsCategoriesList,
      productsBrandList,
    } = productHandler()

    const onSubmit = () => {
      const formData = new FormData()
      formData.append('name', productObject.value.name)
      formData.append('code', productObject.value.code)
      formData.append('category', productObject.value.category)
      formData.append('brand', productObject.value.brand)
      formData.append('pricePEN', productObject.value.pricePEN)
      formData.append('discount', productObject.value.discount)
      formData.append('status', productObject.value.status)
      formData.append('stock', productObject.value.stock)
      formData.append('descriptionInQuotation', productObject.value.descriptionInQuotation)
      formData.append('descriptionProduct', productObject.value.descriptionProduct)
      formData.append('descriptionShort', productObject.value.descriptionShort)
      formData.append('pdfDatasheet', productObject.value.pdfDatasheet)
      formData.append('pdfImages', productObject.value.pdfImages)
      formData.append('imageMain', productObject.value.imageMain)

      productObject.value.imageGallery.forEach((item, index) => {
        formData.append(`imageGallery[${index}]`, item)
      })

      productObject.value.videos.forEach((item, index) => {
        formData.append(`videos[${index}]`, JSON.stringify(item))
      })

      productObject.value.specifications.forEach((item, index) => {
        formData.append(`specifications[${index}]`, JSON.stringify(item))
      })

      store.dispatch('app-products/addProduct', { body: formData })
        .then(() => {
          showNotification({ title: 'Creado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          router.push({ name: 'products' })
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({ title: messageError, icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const pricePENListen = val => {
      productObject.value.priceUSD = (+val.target.value / exchangeRateValue.value).toFixed(6)
    }

    const priceUSDListen = val => {
      productObject.value.pricePEN = (+val.target.value * exchangeRateValue.value).toFixed(6)
    }

    const { inputImageRenderer: renderImageMain } = useInputImageRenderer(refInputElImageMain, base64 => {
      showImageMain.value = base64
    })

    const { inputImageMultipleRenderer: imageGalleryRender } = useInputImageMultipleRenderer(refInputElImageGallery, base64 => {
      showImageGallery.value = base64
    })

    return {
      productObject,

      productStatusesList,
      productsCategoriesList,
      productsBrandList,

      addVideo,
      addSpecifications,
      removeVideo,
      removeSpecification,
      // businessData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,

      refInputElImageMain,
      refInputElImageGallery,
      showImageMain,
      showImageGallery,
      renderImageMain,
      imageGalleryRender,

      pricePENListen,
      priceUSDListen,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
